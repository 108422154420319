// IngredientInput.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import '../styles/IngredientInput.css';
import RecipeList from './RecipeList';
import { auth, db } from '../utils/firebase';
import { addDoc, collection } from 'firebase/firestore';

const animatedComponents = makeAnimated();

const IngredientInput = () => {
    const [user, setUser] = useState(null);
    const apiKey = process.env.REACT_APP_API_KEY;
  const [ingredientOptions, setIngredientOptions] = useState([]); // Options for the autocomplete
  const [selectedIngredients, setSelectedIngredients] = useState([]); // Selected ingredients as pill-shaped buttons
  const [inputValue, setInputValue] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleIngredientChange = (ingredients) => {
    setSelectedIngredients(ingredients);
  };

  const handleClearIngredient = (removedIngredient) => {
    setSelectedIngredients(selectedIngredients.filter((ingredient) => ingredient.value !== removedIngredient.value));
  };

  const storeRecipeInFirebase = (recipe) => {
    try {
      // Reference the "recipes" collection in Firestore
      const recipesCollection = collection(db, 'recipes');
  
      // Add a new document to the "recipes" collection with the recipe data
      addDoc(recipesCollection, recipe);
    } catch (error) {
      console.error('Error storing recipe in Firestore:', error);
    }
  };
  

  const handleRecipeSearch = () => {
    const ingredientList = selectedIngredients.map((ingredient) => ingredient.value).join(',');
    
    // Fetch recipes using the Spoonacular API
    fetch(`https://api.spoonacular.com/recipes/findByIngredients?ingredients=${ingredientList}&number=10&apiKey=${apiKey}&sort=min-missing-ingredients`)
      .then((response) => response.json())
      .then((data) => {
        // Handle the list of recipes returned by the API
        setRecipes(data);
        setSearchPerformed(true);
        // console.log('Recipes found:', data);
        data.forEach((recipe) => {
          storeRecipeInFirebase(recipe);
        });
      })
      .catch((error) => console.error('Error fetching recipes:', error));
  };

  // eslint-disable-next-line
  useEffect(() => {
    
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          // User is authenticated, set the user state
          // console.log('User is authenticated');
          // console.log(authUser);
          setUser(authUser);
        } else {
          // User is not authenticated, set user state to null or handle as needed
          // console.log('User is not authenticated');
          setUser(null);
        }
      });
    if (inputValue) {
      fetch(`https://api.spoonacular.com/food/ingredients/autocomplete?query=${inputValue}&number=10&apiKey=${apiKey}`)
        .then((response) => response.json())
        .then((data) => {
          const options = data.map((ingredient) => ({
            label: ingredient.name,
            value: ingredient.name,
          }));
          setIngredientOptions(options);
        })
        .catch((error) => console.error('Error fetching ingredients:', error));
    }return () => {
        // Cleanup the subscription when the component unmounts
        unsubscribe();
      };
  }, [inputValue, apiKey]);

  return (
    <div className="ingredient-input-container">
      <h2>Find Recipes</h2>
      {user && (
        <><div>
        <Select
        isMulti
        options={ingredientOptions}
        components={animatedComponents}
        onInputChange={(value) => setInputValue(value)} // Update inputValue on user input
        onChange={handleIngredientChange}
        value={selectedIngredients}
        inputValue={inputValue}
        placeholder="Add Ingredients..."
        styles={{ control: (base) => ({ ...base, width: '100%' }) }}
        />
        </div>
        <div className="selected-ingredients">
            {selectedIngredients.map((ingredient) => (
            <div key={ingredient.value} className="ingredient-pill">
                {ingredient.label}
                <button className="remove-button" onClick={() => handleClearIngredient(ingredient)}>
                &times;
                </button>
            </div>
            ))}
        </div>
        <button className="search-button" onClick={handleRecipeSearch}>
            Search Recipes
        </button>
        {searchPerformed && recipes.length > 0 && (
            <RecipeList recipes={recipes} />
        )}</>   
      )}
    </div>
  );
};

export default IngredientInput;
