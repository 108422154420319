import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";

import "../styles/contactus.css";

const ContactUs = () => {
    const form = useRef();
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_7c4yd6i", "template_lvt0uib", form.current, "5id9OB0zH46m1I3nV")
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          alert("Message sent successfully!");
        //   setFormData({
        //     name: "",
        //     email: "",
        //     message: "",
        //   });
        },
        (error) => {
          console.error("Email sending failed:", error);
          alert("Message sending failed. Please try again later.");
        }
      );
  };

  return (
    <div className="contact-us">
      <h1 className="contact-us-title">Contact Us</h1>
      <p className="contact-us-description">Have questions or feedback? Send us a message!</p>
      <div className="contact-form">
        <form ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="user_name"
              value={form.name}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="user_email"
              value={form.email}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={form.message}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
