import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'black',
    margin: '0 10px', // Add spacing between links
    fontSize: '20px',
    padding: '10px'
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className="app-header">
      <Toolbar>
        {/* <Typography className='typography' variant="h6" component="div">
          MealXpert
        </Typography> */}
        <Link to="/" className={classes.link}>
          Home
        </Link>
        <Link to="/scanner" className={classes.link}>
          Scanner
        </Link>
        <Link to="/recipes" className={classes.link}>
          Recipes
        </Link>
        <Link to="/about" className={classes.link}>
          About
        </Link>
        <Link to="/contact" className={classes.link}>
          Contact
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
