import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { db } from '../utils/firebase'; // Import your Firebase configuration
import RecipeList from './RecipeList';

const Recipes = () => {
  const [recipes, setRecipes] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [recipeInstructions, setRecipeInstructions] = useState('');

    const [recipeNutrition, setRecipeNutrition] = useState(null);

    const openRecipePopup = (recipe) => {
        setSelectedRecipe(recipe);
        fetchRecipeInstructions(recipe.id);
        fetchRecipeNutrition(recipe.id);
    };


    const closeRecipePopup = () => {
        setSelectedRecipe(null);
        setRecipeInstructions('');
    };

    const fetchRecipeInstructions = (recipeId) => {

        fetch(`https://api.spoonacular.com/recipes/${recipeId}/information?apiKey=${apiKey}`)
        .then((response) => response.json())
        .then((data) => {
            setRecipeInstructions(data.instructions);
        })
        .catch((error) => console.error('Error fetching recipe instructions:', error));
    };


    const fetchRecipeNutrition = (recipeId) => {
        // Replace 'YOUR_API_KEY' with your Spoonacular API key

        fetch(`https://api.spoonacular.com/recipes/${recipeId}/nutritionWidget.json?apiKey=${apiKey}`)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setRecipeNutrition(data);
        })
        .catch((error) => console.error('Error fetching recipe nutrition:', error));
    };

  // Function to fetch recipes from Firestore
  const fetchRecipesFromFirestore = async () => {
    try {
      // Reference the "recipes" collection in Firestore
      const recipesCollection = collection(db, 'recipes');

      // Get all documents in the "recipes" collection
      const querySnapshot = await getDocs(recipesCollection);

      const recipesData = [];
      querySnapshot.forEach((doc) => {
        // Add each recipe to the recipesData array
        recipesData.push(doc.data());
      });

      // Set the recipes in your component's state
      setRecipes(recipesData);
    } catch (error) {
      console.error('Error fetching recipes from Firestore:', error);
    }
  };

  useEffect(() => {
    // Call the function to fetch recipes from Firestore when the component mounts
    fetchRecipesFromFirestore();
  }, []);

  return (
    <div className="recipe-cards">
    <Grid container spacing={4} className="recipe-cards">
        {recipes.map((recipe) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={recipe.id}>
                <Card className="recipe-card">
                    <CardMedia
                        component="img"
                        alt={recipe.title}
                        // height="200"
                        image={recipe.image}
                    />
                    <CardHeader
                        title={recipe.title}
                        titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                    </CardContent>
                    <div className="view-recipe-button" onClick={() => openRecipePopup(recipe)}>
                        View Recipe
                    </div>
                </Card>
            </Grid>
        ))}
    </Grid>
    {selectedRecipe && (
        <div className="recipe-popup">
            <div className="popup-content scrollable-popup">
            <span className="close-button" onClick={closeRecipePopup}>
                &times;
            </span>
            <img src={selectedRecipe.image} alt={selectedRecipe.title} className="popup-image" />
            <h3>{selectedRecipe.title}</h3>
            <h4>Ingredients:</h4>
            <p>
                {selectedRecipe.missedIngredients.map((ingredient) => ingredient.original).join(', ')}
            </p>
            <h4>Recipe Instructions:</h4>
            <div
                dangerouslySetInnerHTML={{ __html: recipeInstructions }}
                className="recipe-instructions"
            />
            <h4>Nutritional Information:</h4>
            {recipeNutrition && recipeNutrition.nutrients && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.nutrients.map((nutrient) => (
                    <li key={nutrient.name}>
                    <div className="nutrient-label">
                        <strong>{nutrient.name}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}

            <h4>Good:</h4>
            {recipeNutrition && recipeNutrition.good && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.good.map((nutrient) => (
                    <li key={nutrient.title}>
                    <div className="nutrient-label">
                        <strong>{nutrient.title}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}

            <h4>Bad:</h4>
            {recipeNutrition && recipeNutrition.bad && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.bad.map((nutrient) => (
                    <li key={nutrient.title}>
                    <div className="nutrient-label">
                        <strong>{nutrient.title}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}
            </div>
        </div>
        )}
    </div>
  );
};

export default Recipes;
