import React from "react";
import "../styles/aboutus.css"; // Import your CSS styles

const AboutUs = () => {
  return (
    <div className="about-us">
      <h1 className="about-us-title">About MealXpert</h1>
      <p className="about-us-description">
        Welcome to MealXpert, your go-to source for meal inspiration and nutritional insights. We understand that finding the perfect recipe can sometimes be a challenge. That's why we're here to make cooking and eating healthier easier and more enjoyable.
      </p>
      <p className="about-us-description">
        At MealXpert, we offer a unique experience that allows you to discover delicious recipes based on the ingredients you have on hand. Our powerful ingredient-based search helps you make the most of what's in your pantry and fridge.
      </p>
      <p className="about-us-description">
        But that's not all! We also provide a handy scanner tool to check the nutritional information of your favorite products. With a simple scan, you can access essential data to make informed decisions about your meals.
      </p>
      <p className="about-us-description">
        Our mission is to empower you with the knowledge and creativity to cook better, eat healthier, and lead a more wholesome lifestyle. Thank you for choosing MealXpert, your culinary companion on the journey to better meals and better health.
      </p>
    </div>
  );
};

export default AboutUs;
