import React, { useEffect, useRef } from "react";
import config from "./config.json";
import Quagga from "quagga";

const Scanner = props => {
  const { onDetected } = props;
  const apiCallInProgress = useRef(false);
//   const [ data, setData ] = useState(null);

  useEffect(() => {
    Quagga.init(config, err => {
      if (err) {
        console.log(err, "error msg");
      }
      Quagga.start();
      return () => {
        Quagga.stop()
      }
    });

    // const apiCall = (barcode) => {
    //     if (!apiCallInProgress.current) {
    //       // Set the ref to prevent further API calls
    //       apiCallInProgress.current = true;
    //       // Make an API call using the detected barcode
    //       // You can use any HTTP client library like axios or fetch
    //       // Replace the API_URL with your actual API endpoint
    //       console.log(barcode);
    //       const API_URL = `https://world.openfoodfacts.org/api/v2/product/${barcode}.json`;
    //       fetch(API_URL)
    //         .then((response) => response.json())
    //         .then((data) => {
    //           // Handle the API response data here
    //           console.log("API Response:", data);
    //           setData(data);
    //         })
    //         .catch((error) => {
    //           console.error("API Error:", error);
    //         });
    //     }
    //   };

    //detecting boxes on stream
    Quagga.onProcessed(result => {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            Number(drawingCanvas.getAttribute("width")),
            Number(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function(box) {
              return box !== result.box;
            })
            .forEach(function(box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });

    Quagga.onDetected((result) => {
        if (!apiCallInProgress.current) {
          const detectedBarcode = result.codeResult.code;
          onDetected(detectedBarcode);
        //   apiCall(detectedBarcode); // Call the API function with the detected barcode
        }
    });
  }, [onDetected]);

//   const detected = result => {
//     onDetected(result.codeResult.code);
//   };

  return (
    // If you do not specify a target,
    // QuaggaJS would look for an element that matches
    // the CSS selector #interactive.viewport
    <div>
        <div id="interactive" className="viewport">
        </div>

    </div>
  );
};

export default Scanner;
