import React from "react";
import "../styles/productdetails.css"; // Import your CSS styles

const ProductDetails = ({ productData }) => {
  if (!productData || !productData.product) {
    return <div className="product-details">No product data available</div>;
  }

  const {
    product_name,
    brands,
    countries,
    nutriments,
    ecoscore_data,
    allergens,
    traces,
    categories_properties_tags,
    data_quality_tags,
    nutrition_data_per,
    ecoscore_grade,
  } = productData.product;

  return (
    <div className="product-details">
      <h2 className="product-name">{product_name}</h2>
      <p className="product-brand">Brand: {brands}</p>
      <p className="product-country">Country: {countries}</p>

      <h3 className="section-title">Nutritional Information (Per {nutrition_data_per}):</h3>
      <ul className="nutritional-info-list">
        <li>Carbohydrates: {nutriments.carbohydrates}g</li>
        <li>Energy: {nutriments.energy}kcal</li>
        <li>Proteins: {nutriments.proteins}g</li>
        <li>Sugars: {nutriments.sugars}g</li>
      </ul>

      <h3 className="section-title">Eco-Score Information:</h3>
      <p>Eco-Score Grade: {ecoscore_grade || "Unknown"}</p>

      <h3 className="section-title">Allergens:</h3>
      <p className="product-allergens">{allergens || "No allergen information available"}</p>

      <h3 className="section-title">Traces:</h3>
      <p className="product-traces">{traces || "No trace information available"}</p>

      <h3 className="section-title">Categories Properties:</h3>
      <ul className="categories-list">
        {categories_properties_tags.map((category, index) => (
          <li key={index}>{category}</li>
        ))}
      </ul>

      <h3 className="section-title">Data Quality Tags:</h3>
      <ul className="data-quality-list">
        {data_quality_tags.map((tag, index) => (
          <li key={index}>{tag}</li>
        ))}
      </ul>
    </div>
  );
};

export default ProductDetails;
