// RecipeList.js

import React, { useState } from 'react';
import '../styles/recipeList.css';
import { Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// const dotenv = require('dotenv');

const RecipeList = ({ recipes }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [recipeInstructions, setRecipeInstructions] = useState('');

    const [recipeNutrition, setRecipeNutrition] = useState(null);

    const openRecipePopup = (recipe) => {
        setSelectedRecipe(recipe);
        fetchRecipeInstructions(recipe.id);
        fetchRecipeNutrition(recipe.id);
    };


    const closeRecipePopup = () => {
        setSelectedRecipe(null);
        setRecipeInstructions('');
    };

    const fetchRecipeInstructions = (recipeId) => {

        fetch(`https://api.spoonacular.com/recipes/${recipeId}/information?apiKey=${apiKey}`)
        .then((response) => response.json())
        .then((data) => {
            setRecipeInstructions(data.instructions);
        })
        .catch((error) => console.error('Error fetching recipe instructions:', error));
    };


    const fetchRecipeNutrition = (recipeId) => {
        // Replace 'YOUR_API_KEY' with your Spoonacular API key

        fetch(`https://api.spoonacular.com/recipes/${recipeId}/nutritionWidget.json?apiKey=${apiKey}`)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setRecipeNutrition(data);
        })
        .catch((error) => console.error('Error fetching recipe nutrition:', error));
    };

    // return (
    //     <div className="recipe-list-container">
    //     <table className="recipe-table">
    //         <thead>
    //         <tr>
    //             <th>Image</th>
    //             <th>Title</th>
    //             <th>Missed Ingredients</th>
    //             <th>Used Ingredients</th>
    //         </tr>
    //         </thead>
    //         <tbody>
    //         {recipes.map((recipe, index) => (
    //             <tr key={recipe.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
    //             <td>
    //                 <img src={recipe.image} alt={recipe.title} className="recipe-image" />
    //             </td>
    //             <td
    //                 className="recipe-title"
    //                 onClick={() => openRecipePopup(recipe)}
    //                 style={{ cursor: 'pointer' }}
    //             >
    //                 {recipe.title}
    //             </td>
    //             <td className="recipe-ingredients">
    //                 {recipe.missedIngredients.map((ingredient) => ingredient.name).join(', ')}
    //             </td>
    //             <td className="recipe-ingredients">
    //                 {recipe.usedIngredients.map((ingredient) => ingredient.name).join(', ')}
    //             </td>
    //             </tr>
    //         ))}
    //         </tbody>
    //     </table>

    return (
        <div className="recipe-list-container">
            <Grid container spacing={4} className="recipe-cards">
                {recipes.map((recipe) => (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={recipe.id}>
                        <Card className="recipe-card">
                            <CardMedia
                                component="img"
                                alt={recipe.title}
                                // height="200"
                                image={recipe.image}
                            />
                            <CardHeader
                                title={recipe.title}
                                titleTypographyProps={{ variant: 'h6' }}
                            />
                            <CardContent>
                                {/* <Typography variant="body2" color="textSecondary">
                                    Missed Ingredients: {recipe.missedIngredients.map((ingredient) => ingredient.name).join(', ')}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Used Ingredients: {recipe.usedIngredients.map((ingredient) => ingredient.name).join(', ')}
                                </Typography> */}
                            </CardContent>
                            <div className="view-recipe-button" onClick={() => openRecipePopup(recipe)}>
                                View Recipe
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>

        {selectedRecipe && (
        <div className="recipe-popup">
            <div className="popup-content scrollable-popup">
            <span className="close-button" onClick={closeRecipePopup}>
                &times;
            </span>
            <img src={selectedRecipe.image} alt={selectedRecipe.title} className="popup-image" />
            <h3>{selectedRecipe.title}</h3>
            <h4>Ingredients:</h4>
            <p>
                {selectedRecipe.missedIngredients.map((ingredient) => ingredient.original).join(', ')}
            </p>
            <h4>Recipe Instructions:</h4>
            <div
                dangerouslySetInnerHTML={{ __html: recipeInstructions }}
                className="recipe-instructions"
            />
            <h4>Nutritional Information:</h4>
            {recipeNutrition && recipeNutrition.nutrients && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.nutrients.map((nutrient) => (
                    <li key={nutrient.name}>
                    <div className="nutrient-label">
                        <strong>{nutrient.name}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}

            <h4>Good:</h4>
            {recipeNutrition && recipeNutrition.good && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.good.map((nutrient) => (
                    <li key={nutrient.title}>
                    <div className="nutrient-label">
                        <strong>{nutrient.title}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}

            <h4>Bad:</h4>
            {recipeNutrition && recipeNutrition.bad && (
            <div className="nutritional-info">
                <ul>
                {recipeNutrition.bad.map((nutrient) => (
                    <li key={nutrient.title}>
                    <div className="nutrient-label">
                        <strong>{nutrient.title}:</strong>
                    </div>
                    <div className="nutrient-values">
                        {nutrient.amount} {nutrient.unit}
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            )}
            </div>
        </div>
        )}

    </div>
  );
};

export default RecipeList;


