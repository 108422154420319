import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import IngredientInput from './components/IngredientInput';
import SignInRegisterPopup from './components/SignIn';
import { useEffect, useState } from 'react';
// import './styles/IngredientInput.css';
import { auth  } from './utils/firebase';
import Logo from './components/Logo';
import './styles/header.css'; // Create a CSS file for styling
import {BrowserRouter, Route, Routes } from 'react-router-dom'
import Barcode from './components/Barcode';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Recipes from './components/Recipes';




function App() {
  // const [user, setUser] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          // User is authenticated, set the user state
          // setUser(authUser);
          setPopupOpen(false);
        } else {
          // User is not authenticated, set user state to null or handle as needed
          // setUser(null);
          setPopupOpen(true);
        }
      });

      return () => {
        // Cleanup the subscription when the component unmounts
        unsubscribe();
      };
  })
  return (
    <div className="App">
      <div className="header-container">
          <Logo />
          <Header />
      </div>
      <SignInRegisterPopup isOpen={isPopupOpen} onRequestClose={closePopup} />
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" exact element={<IngredientInput/>} />
          <Route path="/scanner" exact element={<Barcode/>} />
          <Route path="/recipes" exact element={<Recipes/>} />
          <Route path="/about" exact element={<AboutUs/>} />
          <Route path="/contact" exact element={<ContactUs/>} />
        </Routes>
      {/* </BrowserRouter> */}
      <Footer/>   
    </div>
  );
}

export default App;
