// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "mealxpert-45372.firebaseapp.com",
    projectId: "mealxpert-45372",
    storageBucket: "mealxpert-45372.appspot.com",
    messagingSenderId: "942005320095",
    appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = firebaseApp.auth();
export{
    db,
    auth
}