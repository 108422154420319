// src/components/SignInRegisterPopup.js

import firebase from 'firebase/compat/app';
import React from 'react';
import Modal from 'react-modal';
import { auth } from '../utils/firebase';
import { Button, TextField } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';

const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    //   width: '300px',
    //   padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
    },
  };

const SignInRegisterPopup = ({ isOpen, onRequestClose }) => {
  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await auth.signInWithPopup(provider);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithEmailPassword = async (email, password) => {
    try {
      await firebase.auth.signInWithEmailAndPassword(email, password);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Sign In / Register"
    >
      <h2>Sign In / Register</h2>
      <Button
        variant="contained"
        color="primary"
        startIcon={<GoogleIcon />}
        onClick={signInWithGoogle}
      >
        Sign In with Google
      </Button>

      <div className="divider">OR</div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          const email = e.target.email.value;
          const password = e.target.password.value;
          signInWithEmailPassword(email, password);
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          name="password"
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
        //   fullWidth
          type="submit"
        >
          Sign In with Email/Password
        </Button>
      </form>
    </Modal>
  );
};

export default SignInRegisterPopup;
