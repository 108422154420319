import React, { useState, useRef } from "react";
import Scanner from "./Scanner";
import "../styles/quaggascanner.css";
import ProductData from "./ProductData";

const Barcode = () => {
  const [camera, setCamera] = useState(false); // Start with the camera disabled
  const [result, setResult] = useState(null);
  const [data, setData] = useState([]);
  const apiCallInProgress = useRef(false);
  const lastDetectedBarcode = useRef(null);

  // Add a delay (in milliseconds) before enabling the camera
  const enableCameraWithDelay = (delay) => {
    setTimeout(() => {
      setCamera(true);
    }, delay);
  };

  const onDetected = newBarcode => {
    if (newBarcode !== lastDetectedBarcode.current) {
      lastDetectedBarcode.current = newBarcode; // Update the reference
      setResult(newBarcode);
      // Close the camera and stop the video feed
      setCamera(false);
      apiCall(newBarcode);
    }
  };

  const apiCall = (barcode) => {
    if (!apiCallInProgress.current) {
      apiCallInProgress.current = true;
      console.log(barcode);
      const API_URL = `https://world.openfoodfacts.org/api/v2/product/${barcode}.json`;
      fetch(API_URL)
        .then((response) => response.json())
        .then((responseData) => {
          apiCallInProgress.current = false;
          console.log("API Response:", responseData);
          setData(responseData);
        })
        .catch((error) => {
          console.error("API Error:", error);
          apiCallInProgress.current = false; // Ensure it's reset on error
        });
    }
  };

  return (
    <div className="App">
      <p>{result ? result : "Scanning..."}</p>
      <button onClick={() => enableCameraWithDelay(1000)}>Start</button> {/* Start with a 1-second delay */}
      <div className="container">
        {camera && <Scanner onDetected={onDetected} />}
      </div>
      <ProductData productData={data} />
    </div>
  );
};

export default Barcode;
