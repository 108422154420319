// Logo.js

import React from 'react';
import logo from '../utils/mealXpert.mp4';
import mobileLogo from '../utils/mealXpert.png';
import '../styles/header.css';


const Logo = () => {
    const isMobile = window.innerWidth <= 768; 
    return (
        <div className="logo-container">
            {isMobile ? (
                // PNG Image for Mobile
                <img src={mobileLogo} alt="Logo" className="logo-image" />
                ) : (
                <video className="logo-video" autoPlay muted controls={false}>
                    <source src={logo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                )}
        </div>
    );
};

export default Logo;
